import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import Image from "antd/lib/image";
import Table from "antd/lib/table";
import Input from "antd/lib/input";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import { getEvents, deleteEvent } from "../../api";
import "./../../App.css";
import { ETimezone } from "../../until/date";

dayjs.tz.setDefault(ETimezone.BANGKOK);
const formatDate = (openFrom, openTo) => {
  const from = dayjs(openFrom).tz();
  const to = dayjs(openTo).tz();

  if (from.format("YYYY-MM-DD") === to.format("YYYY-MM-DD")) {
    return `${from.format("DD MMM YYYY HH:mm")} - ${to.format("HH:mm")}`;
  } else {
    return `${from.format("DD MMM YYYY HH:mm")} - ${to.format(
      "DD MMM YYYY HH:mm"
    )}`;
  }
};

const Lists = () => {
  const [events, setEvents] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });

  const fetchEvents = useCallback(async () => {
    const data = await getEvents(pagination.current, pagination.pageSize);
    setEvents(data);
  }, [pagination]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, pagination]);

  const handleDelete = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteEvent(record.id);
          // Check the response code here
          if (response.status === 200) {
            Swal.fire("Deleted!", "The item has been deleted.", "success");
            fetchEvents();
          } else {
            Swal.fire(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const columns = [
    {
      title: "Photo",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_, record) =>
        record.images[0].url ? (
          <Image src={record.images[0].url} width={100} height={56} />
        ) : (
          <Image
            src="/assets/images/events/default-cover.png"
            width={100}
            height={56}
          />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Events"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.title
          ? record.title.toLowerCase().includes(value.toLowerCase())
          : "",
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      render: (_, record) => (
        <div style={{ width: "120px" }}>
          {formatDate(record.openFrom, record.openTo)}
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "venue",
      key: "location",
      render: (location) => <div style={{ width: "100px" }}>{location}</div>,
    },
    {
      title: "Event fee",
      dataIndex: "isPaid",
      key: "eventFee",
      render: (_, record) => (
        <div>
          {record.isPaid ? (
            <div class="badge bg-danger my-2">
              {new Intl.NumberFormat("th-TH").format(record.paidAmount)} THB
            </div>
          ) : (
            <div class="badge bg-success my-2">Free</div>
          )}
        </div>
      ),
    },
    {
      title: "Attended",
      dataIndex: "capacity",
      key: "attendees",
      render: (_, record) => (
        <div>
          <Link to={`/events/${record.id}/attendees`}>{`
            ${record.confirmedAndAttendedCount}/${record.capacity}`}</Link>
        </div>
      ),
    },
    {
      title: "Waiting",
      dataIndex: "capacity",
      key: "attendees",
      render: (_, record) => (
        <div>
          <Link
            to={`/events/${record.id}/attendees`}
          >{`${record.waitingCount}`}</Link>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <div style={{ width: "120px" }}>
          {dayjs(record.createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_, record) => (
        <div className="button-group">
          <Link to={`/events/${record.id}/edit`}>
            <button className="btn btn-warning btn-sm">
              <EditOutlined /> Edit
            </button>
          </Link>
          <button
            className="btn btn-danger btn-sm ml-5"
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined /> Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <Link to="/events/create" className="btn btn-primary float-end mt-n1">
          <i class="fas fa-plus"></i>
          <PlusOutlined /> New Event
        </Link>
        <h1 className="h3 mb-3">Events</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Events</h5>
              </div>
              <div className="card-body">
                <Table
                  dataSource={events?.items}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    current: pagination.current,
                    pageSize: events?.meta?.itemsPerPage,
                    total: events?.meta?.total ? events.meta.total : 0,
                    pageSizeOptions: ["10", "20", "50"],
                  }}
                  onChange={handleTableChange}
                  size="middle"
                  scroll={{
                    x: "calc(700px + 50%)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Lists;
