import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import {
  Form,
  Upload,
  Input,
  Col,
  Row,
  Button,
  Radio,
  message,
  DatePicker,
  InputNumber,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import EventLivePreview from "../../components/events/EventLivePreview";
import { getEvent, updateEvent } from "../../api";
import "./../../App.css";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { formatEventTimeToBangkok, convertToGMTPlus7 } from "../../until/date";
const { RangePicker } = DatePicker;

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [event, setEvent] = useState();

  const [hostFileList, setHostFileList] = useState([]);

  const handleHostsChange =
    (hostNumber) =>
    ({ fileList }) => {
      if (fileList.length === 0) {
        setHostFileList((prevList) => {
          if (!Array.isArray(prevList)) {
            prevList = [];
          }
          const newList = [...prevList];
          newList[hostNumber - 1] = undefined;
          formik.setFieldValue(`hosts[${hostNumber - 1}].image`, "");
          return newList;
        });
        return;
      }

      const updatedFile = fileList[fileList.length - 1];
      setHostFileList((prevList) => {
        if (!Array.isArray(prevList)) {
          prevList = [];
        }
        const newList = [...prevList];
        newList[hostNumber - 1] = {
          uid: updatedFile.uid,
          name: updatedFile.name,
          status: "done",
          url: updatedFile.response
            ? updatedFile.response.url
            : updatedFile.url,
        };
        formik.setFieldValue(
          `hosts[${hostNumber - 1}].image`,
          updatedFile.response ? updatedFile.response.url : updatedFile.url
        );
        return newList;
      });
    };

  const renderHost = (hostNumber) => (
    <Form.Item label={`Speaker ${hostNumber}`}>
      <Row gutter={16} align="top">
        <Col xs={7} sm={5}>
          <Upload
            accept=".jpg,.png"
            multiple={false}
            action={`${process.env.REACT_APP_API_URL}/upload`}
            listType="picture-circle"
            fileList={
              hostFileList[hostNumber - 1] &&
              Object.keys(hostFileList[hostNumber - 1]).length > 0
                ? [hostFileList[hostNumber - 1]]
                : null
            }
            onChange={handleHostsChange(hostNumber)}
          >
            {hostFileList[hostNumber - 1] &&
            Object.keys(hostFileList[hostNumber - 1]).length > 0
              ? null
              : uploadButton}
          </Upload>
        </Col>
        <Col xs={17} sm={19}>
          <Row align="middle" style={{ marginBottom: 16, marginTop: 8 }}>
            <Col xs={6}>Name:</Col>
            <Col xs={18}>
              <Input
                name={`hosts[${hostNumber - 1}].name`}
                onChange={formik.handleChange}
                value={
                  formik.values.hosts[hostNumber - 1] &&
                  formik.values.hosts[hostNumber - 1].name
                    ? formik.values.hosts[hostNumber - 1].name
                    : ""
                }
              />
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={6}>Job Title:</Col>
            <Col xs={18}>
              <Input
                name={`hosts[${hostNumber - 1}].position`}
                onChange={formik.handleChange}
                value={
                  formik.values.hosts[hostNumber - 1] &&
                  formik.values.hosts[hostNumber - 1].position
                    ? formik.values.hosts[hostNumber - 1].position
                    : ""
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );

  const transformToAntdFormat = (hosts) => {
    return hosts
      .filter((host) => host.image)
      .map((host, index) => ({
        uid: "-" + index,
        name: host.name,
        url: host.image,
        status: "done",
      }));
  };
  const fetchEvent = useCallback(
    async (id) => {
      try {
        const eventResult = await getEvent(id);

        setEvent(eventResult.data);
        setFileList(eventResult.data.images);
        const hostFileList =
          eventResult.data.hosts.length > 0
            ? transformToAntdFormat(eventResult.data.hosts)
            : {};
        setHostFileList(hostFileList);
      } catch (error) {
        console.error(error);
        // navigate('/events');
      }
    },
    [navigate]
  );

  useEffect(() => {
    fetchEvent(id);
  }, [fetchEvent, id]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChange = ({ fileList: newFileList }) => {
    if (fileList.length <= 4) {
      setFileList(fileList);
    } else {
      message.error("You can only upload up to 4 files!");
    }
    setFileList(newFileList);

    const updatedImages = newFileList.map((file) => {
      if (file.response) {
        return {
          ...file,
          thumbUrl: file.response.thumbUrl,
          url: file.response.url,
        };
      } else {
        return file;
      }
    });

    if (updatedImages.length > 0) {
      formik.setFieldValue("images", updatedImages);
    } else {
      formik.setFieldValue("images", [{ url: "" }]);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: event?.title || "",
      description: event?.description || "",
      requirement: event?.requirement || "",
      images: event?.images.length > 0 ? event?.images : [{ url: "" }],
      venue: event?.venue || "",
      capacity: event?.capacity || 0,
      openFrom: dayjs(event?.openFrom || null).isValid()
        ? formatEventTimeToBangkok(event.openFrom)
        : null,
      openTo: dayjs(event?.openTo || null).isValid()
        ? formatEventTimeToBangkok(event.openTo)
        : null,
      registrationFrom: event?.registrationFrom
        ? formatEventTimeToBangkok(event.registrationFrom)
        : null,
      registrationTo: event?.registrationTo
        ? formatEventTimeToBangkok(event.registrationTo)
        : null,
      isPrivate: event?.isPrivate || false,
      isPaid: event?.isPaid || false,
      paidAmount: event?.paidAmount || 0,
      hosts:
        event?.hosts || Array(5).fill({ image: {}, name: "", position: "" }),
      isFromAdmin: true,
      memberId: process.env.REACT_APP_ADMIN_ID,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      images: Yup.array()
        .of(
          Yup.object().shape({
            url: Yup.string().required("An image URL is required"),
          })
        )
        .required("At least one image is required"),
    }),
    onSubmit: async (values) => {
      try {
        if (
          !values?.openFrom ||
          !values?.openTo ||
          [values?.openFrom, values?.openTo].includes("Invalid")
        ) {
          formik.setFieldError("eventDate", "Event Date is required");
          return;
        }
        values.openFrom = values?.openFrom
          ? convertToGMTPlus7(values.openFrom)
          : null;
        values.openTo = values?.openTo
          ? convertToGMTPlus7(values.openTo)
          : null;
        values.registrationFrom = values?.registrationFrom
          ? convertToGMTPlus7(values.registrationFrom)
          : null;
        values.registrationTo = values?.registrationTo
          ? convertToGMTPlus7(values.registrationTo)
          : null;
        values.hosts = values.hosts.filter((host) => {
          return (
            (host.name !== null && host.name !== "") ||
            (host.image !== null && host.image !== "")
          );
        });

        values.hosts = values.hosts.length > 0 ? values.hosts : [];

        // console.log("values : ", values);

        const response = await updateEvent(id, values);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Event Updated",
            text: "The event has been updated successfully.",
          });
          navigate("/events");
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
        // Handle error response
      }
    },
  });

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Event</h1>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Edit From</h5>
              </div>
              {!event ? (
                <div>Loading...</div>
              ) : (
                <div className="card-body">
                  <Form
                    {...{
                      labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                      },
                      wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                      },
                    }}
                    onSubmit={formik.handleSubmit}
                  >
                    <Form.Item
                      label="Cover"
                      validateStatus={formik.errors.images ? "error" : ""}
                      help={
                        formik.errors.images &&
                        formik.errors.images.map((err, index) => (
                          <div key={index}>{err.url}</div>
                        ))
                      }
                    >
                      <Upload
                        accept=".jpg,.png"
                        multiple={false}
                        action={`${process.env.REACT_APP_API_URL}/upload`}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                      >
                        {fileList.length >= 4 ? null : uploadButton}
                      </Upload>
                    </Form.Item>

                    <Form.Item
                      label="Title"
                      validateStatus={formik.errors.title ? "error" : ""}
                      help={formik.errors.title}
                    >
                      <Input
                        name="title"
                        placeholder="eg: Leveraging Advanced Technologies to Propel Your Business Forward"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        id="error"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Description"
                      validateStatus={formik.errors.description ? "error" : ""}
                      help={formik.errors.description}
                    >
                      <Input.TextArea
                        name="description"
                        showCount
                        maxLength={2000}
                        rows={12}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Requirement"
                      validateStatus={formik.errors.requirement ? "error" : ""}
                      help={formik.errors.requirement}
                    >
                      <Input.TextArea
                        name="requirement"
                        showCount
                        maxLength={2000}
                        rows={8}
                        onChange={formik.handleChange}
                        value={formik.values.requirement}
                      />
                    </Form.Item>

                    <Form.Item
                      label={
                        <div>
                          <div style={{ whiteSpace: "pre" }}>Event Date</div>
                          <div
                            style={{
                              whiteSpace: "pre",
                              fontSize: "10px",
                              color: "#ACACAC",
                            }}
                          >
                            (BKK Time)
                          </div>
                        </div>
                      }
                      validateStatus={formik.errors.eventDate ? "error" : ""}
                      help={formik.errors.eventDate}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        value={[
                          formik.values.openFrom
                            ? dayjs(formik.values.openFrom, "YYYY-MM-DD HH:mm")
                            : null,
                          formik.values.openTo
                            ? dayjs(formik.values.openTo, "YYYY-MM-DD HH:mm")
                            : null,
                        ]}
                        onChange={(dates, dateStrings) => {
                          if (dateStrings && dateStrings.length >= 2) {
                            formik.setFieldValue("openFrom", dateStrings[0]);
                            formik.setFieldValue("openTo", dateStrings[1]);
                            formik.setFieldValue(
                              "registrationTo",
                              dateStrings[1]
                            );
                          }
                        }}
                        onOk={(dates, dateStrings) => {
                          if (dateStrings && dateStrings.length >= 2) {
                            formik.setFieldValue("openFrom", dateStrings[0]);
                            formik.setFieldValue("openTo", dateStrings[1]);
                            formik.setFieldValue(
                              "registrationTo",
                              dateStrings[1]
                            );
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={
                        <div>
                          <div style={{ whiteSpace: "pre" }}>
                            Booking Start Date
                          </div>
                          <div
                            style={{
                              whiteSpace: "pre",
                              fontSize: "10px",
                              color: "#ACACAC",
                            }}
                          >
                            (BKK Time)
                          </div>
                        </div>
                      }
                      validateStatus={
                        formik.errors.registrationDate ? "error" : ""
                      }
                      help={formik.errors.registrationDate}
                    >
                      <DatePicker
                        format="YYYY-MM-DD HH:mm"
                        showTime={{
                          format: "HH:mm",
                        }}
                        value={
                          formik.values.registrationFrom
                            ? dayjs(
                                formik.values.registrationFrom,
                                "YYYY-MM-DD HH:mm"
                              )
                            : null
                        }
                        onChange={(dates, dateStrings) => {
                          formik.setFieldValue("registrationFrom", dateStrings);
                        }}
                      />
                      {/* <RangePicker
                        showTime={{
                          format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                        value={[
                          formik.values.registrationFrom
                            ? dayjs(
                                formik.values.registrationFrom,
                                'YYYY-MM-DD HH:mm'
                              )
                            : null,
                          formik.values.registrationTo
                            ? dayjs(
                                formik.values.registrationTo,
                                'YYYY-MM-DD HH:mm'
                              )
                            : null,
                        ]}
                        onChange={(dates, dateStrings) => {
                          if (dateStrings && dateStrings.length >= 2) {
                            formik.setFieldValue(
                              'registrationFrom',
                              dateStrings[0]
                            );
                            formik.setFieldValue(
                              'registrationTo',
                              dateStrings[1]
                            );
                          }
                        }}
                        onOk={(dates, dateStrings) => {
                          if (dateStrings && dateStrings.length >= 2) {
                            formik.setFieldValue(
                              'registrationFrom',
                              dateStrings[0]
                            );
                            formik.setFieldValue(
                              'registrationTo',
                              dateStrings[1]
                            );
                          }
                        }}
                      /> */}
                    </Form.Item>

                    <Form.Item
                      label="Location"
                      validateStatus={formik.errors.venue ? "error" : ""}
                      help={formik.errors.venue}
                    >
                      <Input
                        name="venue"
                        placeholder="eg: Sofitel Bangkok"
                        value={formik.values.venue}
                        onChange={(e) =>
                          formik.setFieldValue("venue", e.target.value)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Capacity"
                      validateStatus={formik.errors.capacity ? "error" : ""}
                      help={formik.errors.capacity}
                    >
                      <InputNumber
                        name="capacity"
                        min={0}
                        max={10000}
                        value={formik.values.capacity}
                        onChange={(value) =>
                          formik.setFieldValue("capacity", value)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Type"
                      validateStatus={formik.errors.capacity ? "error" : ""}
                      help={formik.errors.capacity}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          formik.setFieldValue(
                            "isPrivate",
                            e.target.value === "private"
                          )
                        }
                        value={formik.values.isPrivate ? "private" : "public"}
                      >
                        <Radio value="public">Public</Radio>
                        <Radio value="private">Private</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      label="Event fee"
                      validateStatus={formik.errors.isPaid ? "error" : ""}
                      help={formik.errors.isPaid}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          formik.setFieldValue(
                            "isPaid",
                            e.target.value === "paid"
                          )
                        }
                        value={formik.values.isPaid ? "paid" : "free"}
                      >
                        <Radio value="free">Free</Radio>
                        <Radio value="paid">Paid</Radio>
                      </Radio.Group>
                    </Form.Item>

                    {formik.values.isPaid && (
                      <Form.Item
                        label="Paid Amount"
                        validateStatus={formik.errors.paidAmount ? "error" : ""}
                        help={formik.errors.paidAmount}
                      >
                        <InputNumber
                          name="paidAmount"
                          min={0}
                          max={1000000}
                          defaultValue={formik.values.paidAmount}
                          onChange={(value) =>
                            formik.setFieldValue("paidAmount", value)
                          }
                        />
                      </Form.Item>
                    )}

                    {Array.from(
                      {
                        length:
                          formik.values.hosts.length < 5
                            ? 5
                            : formik.values.hosts.length,
                      },
                      (_, index) => renderHost(index + 1)
                    )}

                    <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
                      <Button type="primary" onClick={formik.handleSubmit}>
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </div>

          <EventLivePreview formik={formik} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Edit;
